var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "label",
      {
        staticStyle: {
          "text-align": "justify",
          "font-size": "18px",
          color: "black"
        }
      },
      [_vm._v("Reset Your Password")]
    ),
    _c("br"),
    _c("label", {
      staticClass: "error-msg",
      domProps: { innerHTML: _vm._s(_vm.errorMessage) }
    }),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.user.email,
              expression: "user.email"
            }
          ],
          attrs: {
            type: "email",
            placeholder: "Enter Your Registered Email",
            autocomplete: "email",
            required: ""
          },
          domProps: { value: _vm.user.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.user, "email", $event.target.value)
            }
          }
        }),
        _c("input", {
          staticClass: "btn-lg-green",
          attrs: {
            disabled: _vm.busy,
            type: "submit",
            value: "Send Email Verification Code"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("Welcome,"),
      _c("br"),
      _vm._v("\n    It’s "),
      _c("span", { staticClass: "green-text" }, [_vm._v("PICKL")]),
      _vm._v(" Time")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }