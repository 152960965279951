var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "two-step" }, [
    _c("h2", [_vm._v("Two-factor authentication")]),
    _c("label", {
      staticClass: "error-msg",
      domProps: { innerHTML: _vm._s(_vm.errorMessage) }
    }),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "div",
          [
            _vm._m(0),
            _c("br"),
            _vm._l(6, function(n) {
              return _c("input", {
                key: n,
                ref: "two-step",
                refInFor: true,
                attrs: { type: "tel", maxlength: "1", autofocus: 1 == n },
                on: {
                  keydown: function($event) {
                    return _vm.handleKeyPress(n - 1, $event)
                  }
                }
              })
            })
          ],
          2
        ),
        _c("div", [
          _c("label", [_vm._v("Set New Password")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password"
              }
            ],
            attrs: {
              type: "password",
              placeholder: "Enter Your New Password",
              autocomplete: "email",
              required: "required"
            },
            domProps: { value: _vm.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              }
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.confirmPassword,
                expression: "confirmPassword"
              }
            ],
            attrs: {
              type: "password",
              placeholder: "Enter Your New Password Again",
              autocomplete: "email",
              required: "required"
            },
            domProps: { value: _vm.confirmPassword },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.confirmPassword = $event.target.value
              }
            }
          }),
          _c("label", { staticStyle: { color: "red" } }, [
            _vm._v(_vm._s(_vm.passwordError))
          ])
        ]),
        _c("div", { staticClass: "resend-text" }, [
          _vm._v("Didn't get a verification code? "),
          _c(
            "a",
            {
              staticClass: "purple-link",
              attrs: { href: "javascript:;" },
              on: { click: _vm.sendVerificationCodeAgain }
            },
            [_vm._v("Send Again")]
          )
        ]),
        _c("input", {
          staticClass: "btn-lg-green",
          attrs: { disabled: _vm.busy, type: "submit", value: "Reset Password" }
        })
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Enter your 6 digit Otp sent to your "),
      _c("u", [_c("strong", [_vm._v("email")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }