<template>
    <div class="two-step">
        <h2>Two-factor authentication</h2>
        <label class="error-msg" v-html="errorMessage"></label>
        <form action="#" v-on:submit.prevent="onSubmit">
            <div>
              <label>Enter your 6 digit Otp sent to your <u><strong>email</strong></u></label><br/>
                <input type="tel" maxlength="1" v-for="n in 6" :key="n" ref="two-step" v-on:keydown="handleKeyPress(n - 1, $event)" :autofocus="1 == n" />
            </div>
            <div>
              <label>Set New Password</label>
              <input type="password" v-model="password" placeholder="Enter Your New Password" autocomplete="email" required="required">
              <input type="password" v-model="confirmPassword" placeholder="Enter Your New Password Again" autocomplete="email" required="required">
              <label style="color: red;">{{ passwordError }}</label>
            </div>
            <div class="resend-text">Didn't get a verification code? <a href="javascript:;" @click="sendVerificationCodeAgain" class="purple-link">Send Again</a></div>
            <input :disabled="busy" type="submit" class="btn-lg-green" value="Reset Password" />
        </form>
    </div>
</template>

<script>
export default {
  name: 'OtpResetPassword',
  data: function () {
    return {
      password: '',
      confirmPassword: '',
      passwordError: ''
    }
  },
  props: {
    errorMessage: String,
    user: Object,
    busy: Boolean
  },
  methods: {
    handleKeyPress (n, e) {
      let twoStep = this.$refs['two-step']
      let key = e.keyCode
      if (key >= 96 && key <= 105) key = key - 48

      if (key >= 48 && key <= 57) {
        twoStep[n].value = String.fromCharCode(key)
        if (n !== 5) {
          twoStep[n + 1].focus()
        }
      } else if (key === 8) {
        if (twoStep[n].value.length > 0) {
          twoStep[n].value = ''
        } else if (n !== 0) {
          twoStep[n - 1].value = ''
        }

        if (n !== 0) twoStep[n - 1].focus()
      }
      if (key !== 9 && key !== 13) {
        e.preventDefault()
      }
    },
    onSubmit () {
      this.passwordError = ''
      if (this.password.length < 6) {
        this.passwordError = 'Password should be 6 chars minimum'
        return false
      }
      if (this.password !== this.confirmPassword) {
        this.passwordError = 'Password and confirm password should be same'
        return false
      }
      let twoStep = this.$refs['two-step']
      let code = ''
      for (var n = 0; n < 6; ++n) {
        code += twoStep[n].value
      }
      let data = {
        code: code,
        password: this.password,
        confirmPassword: this.confirmPassword
      }
      this.$emit('submit', data)
    },
    sendVerificationCodeAgain () {
      this.$emit('sendVerificationCodeAgain', this.user)
    }
  }
}
</script>

<style scoped>
h2 {
    font-size: 54px;
    line-height: 72px;
    margin-bottom: 20px;
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
}

.two-step {
    font-size: 17px;
    color: rgba(0,0,0,0.7);
}

.two-step input[type=tel] {
    width: 14%;
    margin-right: 3.2%;
    height: 54px;
    font-size: 24px;
    padding: 15px 0;
    text-align: center;
    margin-top: 5px;
    font-weight: 700;
}

.two-step input[type=tel]:last-child {
    margin-right: 0;
}

.two-step .resend-text {
    font-size: 18px;
    margin: 4px 0;
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 35px;
        line-height: 45px;
    }

    .two-step input[type=tel] {
        height: 40px;
        padding: 8px 0;
    }
}
</style>
