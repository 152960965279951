<template>
    <div>
        <h2>Welcome,<br />
        It’s <span class="green-text">PICKL</span> Time</h2>
        <label style="text-align:justify;font-size:18px;color:black;">Reset Your Password</label><br/>
        <label class="error-msg" v-html="errorMessage"></label>
        <form action="#" v-on:submit.prevent="onSubmit">
            <input type="email" v-model="user.email" placeholder="Enter Your Registered Email" autocomplete="email" required/>
            <input :disabled="busy" type="submit" value="Send Email Verification Code" class="btn-lg-green" />
        </form>
    </div>
</template>

<script>
export default {
  name: 'EmailForm',
  props: {
    errorMessage: String,
    busy: Boolean
  },
  data () {
    return {
      user: {
        email: ''
      }
    }
  },
  methods: {
    onSubmit: function () {
      this.$emit('submit', this.user)
    }
  }
}
</script>

<style scoped>
h2 {
  font-size: 54px;
  line-height: 72px;
  margin-bottom: 20px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
}

@media only screen and (max-width: 575px) {
  h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
input:disabled {
  background: gainsboro;
}
</style>
