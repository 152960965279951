<template>
    <div class="outer-box vertical-center-transform">
        <div class="posRel">
          <div class="col-xs-12 col-md-6">
              <div class="col-xs-12 login-box">
                  <div class="col-xs-12 text-center">
                      <img class="pickl-logo" src="/img/pickl-logo.png" />
                  </div>
                  <EmailForm :busy="busy" v-if="!accountVerified"  @submit="sendResetPasswordEmail" :errorMessage="errorMessage" />
                  <OtpResetPassword :busy="busy" v-if="accountVerified & !otpVerified" @submit="resetPassword" @sendVerificationCodeAgain="sendResetPasswordEmail" :user="user"  :errorMessage="errorMessage"/>
                  <router-link to="/brand/login" style="float:left;">Remember password?</router-link><br/><br/>
                  <div class="two-step" v-if="accountVerified & otpVerified">
                    <h2 style="text-align:center;">Select Brand To Login</h2>
                    <form v-on:submit.prevent="switchUserBrand" data-vv-scope="switch-user-brand">
                      <div>
                        <select v-model="brandId" name="brandId" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('switch-user-brand.brandId') }">
                          <option value="">Select Brand</option>
                          <option v-for="userBrand in userBrands" :key="userBrand.id" :value="userBrand.brand.id" >{{userBrand.brand.name}}</option>
                        </select>
                        <button class="btn-lg-green" type="submit" :disabled="busy">LOGIN NOW</button><br/><br/>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
          <div class="col-xs-12 col-md-6 video-out vertical-center-transform">
              <div class="video-box">
                  <!-- <h3>Manage Pickl, Picklrs Easily</h3> -->
                  <TutorialVideos></TutorialVideos>
              </div>
          </div>
          <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import TutorialVideos from '@/components/common/TutorialVideos.vue'
import EmailForm from '@/components/common/EmailForm.vue'
import OtpResetPassword from '@/components/SAdmin/OtpResetPassword'

export default {
  name: 'login',
  components: {
    TutorialVideos,
    EmailForm,
    OtpResetPassword
  },
  data: function () {
    return {
      busy: false,
      accountVerified: false,
      errorMessage: '',
      user: {},
      otpVerified: false
    }
  },
  mounted () {
    if (typeof this.$route.query.refer !== 'undefined') {
      this.getInvitationDetails()
    }
  },

  methods: {
    resetPassword (data) {
      this.busy = true
      this.errorMessage = ''
      data.email = this.user.email
      this.$http
        .post('reset-password-with-otp', data)
        .then(
          function (response) {
            this.$notify({ type: 'success', text: 'Password has been reset, try logging in with new password now!' })
            this.$router.push('/brand/login')
          },
          function (response) {
            // this.accountVerified = false
            this.errorMessage = response.body.message
          }
        ).finally(() => {
          this.busy = false
        })
    },
    sendResetPasswordEmail (user) {
      this.busy = true
      this.errorMessage = ''
      this.$http
        .post('send-email-verification-code', user)
        .then(
          function (response) {
            this.accountVerified = true
            this.user = user
            this.$notify({ type: 'success', text: 'Verification code has been send to your registered email!' })
          },
          function (response) {
            this.accountVerified = false
            this.errorMessage = response.body.message
          }
        ).finally(() => {
          this.busy = false
        })
    },
    pushToRegisterPage () {
      if (typeof this.$route.query.redirect !== 'undefined' && this.$route.query.redirect !== null) {
        this.$router.push('/brand/signup?redirect=' + encodeURIComponent(this.$route.query.redirect))
      } else {
        this.$router.push('/brand/signup')
      }
    }
  }
}
</script>

<style scoped>
.outer-box {
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;
  background-image: url("/img/background-portrait.png");
  background-size: 50% 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pickl-logo {
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
  border-radius: 7px;
}

.login-box {
  margin: 5% auto 0;
  padding: 10px 90px 20px;
}

.video-out {
  padding-left: 0;
    left: auto;
    right: 0;
}

.video-box {
  margin: 0 auto;
  /* margin: 0 0 20px; */
  padding: 25px 0 15px;
}

.video-box h3 {
  font-weight: 700;
  text-align: center;
  font-size: 44px;
  color: black;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .login-box {
    padding: 10px 35px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .outer-box {
    background-size: 100% 100vh;
        background-repeat: repeat-y;
        position: static;
        transform: none;
  }

  .video-out {
    padding-left: 15px;
        min-height: auto;
        position: static;
        transform: none;
    }

    .video-box {
        margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .login-box {
    padding: 15px;
  }

  .video-box h3 {
    font-size: 36px;
        padding: 15px;
    }
}

@media only screen and (max-height: 660px) {
    .outer-box {
        position: static;
        transform: none;
  }
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
</style>
